import { Button, Container, Divider, Flex, Heading } from '@chakra-ui/react';

import Link from 'next/link';
import React from 'react';

export const Business = () => {
  return (
    <Container mt={4}>
      <Flex direction="column" gap={4}>
        <Heading as="h2">L&apos;esprit de l&apos;Italie!</Heading>
        <p>
          Un restaurant de produits de qualité, sourcés sans intermédiaire auprès de producteurs
          italiens, le tout généreusement cuisinés, selon nos recettes de famille par le Chef et sa
          Brigade.
        </p>
        <Divider />
        <Heading as="h2">Nos ingrédients</Heading>
        <p>
          Un comptoir traiteur de produits frais, d’antipasti, de plats cuisinés en vente à
          emporter. Un rayon d’épicerie fine de produits régionaux Terre & Mer, sélectionnés au cœur
          de la Campagne Italienne.
        </p>
      </Flex>
    </Container>
  );
};
