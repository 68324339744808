import { Box, Link, Text } from '@chakra-ui/react';
import { useMatomo } from '@datapunt/matomo-tracker-react';

export const Footer = () => {
  const { trackEvent } = useMatomo();
  return (
    <Box backgroundColor="gray.50" position={'fixed'} bottom={0} width={'100%'} p={2}>
      <Text
        onClick={() => trackEvent({ category: 'Page Action', action: 'external-link-123miam' })}
      >
        ⚡ Powered by <Link href="http://123miam.com">123miam</Link>
      </Text>
    </Box>
  );
};
