import { Avatar, Box, Center } from '@chakra-ui/react';
import { FC, SyntheticEvent } from 'react';

import { Business } from 'components/main/Business';
import { Owner } from 'components/main/Owner';
import { useMatomo } from '@datapunt/matomo-tracker-react';

interface Props {
  setDrawerComponent: (arg: FC) => void | undefined;
  setDrawerTitle: (arg: string) => void;
  openDrawer: () => void;
}

export const HeroHeader: FC<Props> = ({ setDrawerComponent, setDrawerTitle, openDrawer }) => {
  const { trackEvent } = useMatomo();

  return (
    <Box as="header" justifyContent={'center'}>
      <Box
        bgImage={`url("food-truck-unsplash.jpg")`}
        bgSize={'cover'}
        h="30vh"
        cursor="pointer"
        onClick={() => {
          trackEvent({ category: 'Page Action', action: 'show-business' });
          setDrawerComponent(Business);
          setDrawerTitle('Pizza Mario');
          openDrawer();
        }}
      >
        <Center>
          <Avatar
            src="https://i.pravatar.cc/150?u=a042581f4e29026024d"
            size="lg"
            position="relative"
            top="calc(30vh - 4rem)"
            m={'auto'}
            zIndex="10"
            loading="lazy"
            showBorder={true}
            onClick={(e: SyntheticEvent) => {
              trackEvent({ category: 'Page Action', action: 'show-owner' });
              e.stopPropagation();
              setDrawerComponent(Owner);
              setDrawerTitle('Mais qui est Mario ?');
              openDrawer();
            }}
          />
        </Center>
      </Box>
    </Box>
  );
};
