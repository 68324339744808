import { Button, Flex, ThemingProps } from '@chakra-ui/react';

import { FC } from 'react';
import Link from 'next/link';
import { useMatomo } from '@datapunt/matomo-tracker-react';

interface Props {
  label: string;
  linkTo: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | undefined;
}

const HomeScreenButton: FC<Props> = ({ label, linkTo, size }) => {
  const { trackEvent } = useMatomo();

  return (
    <Flex justifyContent={'center'} my={4}>
      <Link href={`/${linkTo}`} passHref>
        <Button
          size={size || 'md'}
          shadow="xl"
          variant={'outline'}
          colorScheme="red"
          onClick={() =>
            trackEvent({ category: 'Navigation', action: `show-${label.toLowerCase()}` })
          }
        >
          {label}
        </Button>
      </Link>
    </Flex>
  );
};

export const HomeButtons = () => {
  return (
    <Flex direction="column">
      <HomeScreenButton label="Pizzas" linkTo="listing" size={'lg'} />
      <Flex direction="row" gap={4} wrap={'wrap'} justifyContent="space-between">
        <HomeScreenButton label="Boissons" linkTo="drinks" />
        <HomeScreenButton label="Desserts" linkTo="desserts" />
      </Flex>
    </Flex>
  );
};
