import { Button, Flex, Text } from '@chakra-ui/react';
import React, { FC, useEffect } from 'react';

import { IngredientsByCategory } from './IngredientsByCategory';
import ingredientsCategories from 'data/ingredients.json';
import { useRouter } from 'next/router';

interface Props {
  onClose: Function;
  changeTitle: Function;
}

const MAX_NB_INGREDIENTS = 5;

export const FilterByIngredients: FC<Props> = ({ changeTitle, onClose }) => {
  const router = useRouter();
  useEffect(() => changeTitle(`Qu'est ce que vous aimez ?`));

  return (
    <Flex as="main" minHeight="calc(100vh + 2rem)" direction={'column'}>
      <Text as="i">
        Quels ingrédients aimeriez vous avoir sur votre pizza ? ({MAX_NB_INGREDIENTS} maxi)
      </Text>

      {ingredientsCategories
        .sort((a, b) => a.priority - b.priority)
        .map((i) => (
          <IngredientsByCategory
            key={i.type}
            heading={i.name}
            ingredientsOfCategory={i.ingredients}
            maxNbIngredients={MAX_NB_INGREDIENTS}
          />
        ))}

      <Button
        shadow={'2xl'}
        variant="outline"
        colorScheme="red"
        backgroundColor={'white'}
        position={'absolute'}
        left="50%"
        transform="translate(-50%, -50%)"
        bottom={4}
        onClick={() => {
          onClose();
          setTimeout(() => {
            router.push('/listing');
          }, 500);
        }}
      >
        Filtrer !
      </Button>
    </Flex>
  );
};
