import * as UAParser from 'ua-parser-js';

import { Button } from '@chakra-ui/react';
import { FiMapPin } from 'react-icons/fi';
import React, { FC, useMemo } from 'react';

import Link from 'next/link';
import { useMatomo } from '@datapunt/matomo-tracker-react';

interface Props {
  street: string;
  city: string;
  label?: string;
}

export const GeoLocBtn: FC<Props> = ({ street, city, label }) => {
  const { trackEvent } = useMatomo();
  const encodedUri = useMemo(() => encodeURI(`${street} ${city}`), [street, city]);

  const uri = useMemo(() => {
    const parser = new UAParser.UAParser();
    const os = parser.getOS();
    const isIOSDevice = os?.name === 'iOS';
    return isIOSDevice ? `maps://?q=${encodedUri}` : `geo://?q=${encodedUri}`;
  }, [encodedUri]);

  return (
    <Link href={uri} passHref>
      <Button
        iconSpacing={!!label ? 2 : 0}
        leftIcon={<FiMapPin />}
        size="sm"
        variant="outline"
        aria-label="Calendrier et horaires"
        shadow={!!label ? 'md' : 'xs'}
        onClick={() =>
          trackEvent({
            category: 'Page Action',
            action: 'show-geoloc-map',
            customDimensions: [
              { id: 1, value: city },
              { id: 2, value: street },
            ],
          })
        }
      >
        {label}
      </Button>
    </Link>
  );
};
