import React, { FC } from 'react';

import { Box } from '@chakra-ui/react';

interface Props {
  shadow?: string;
}

export const Card: FC<Props> = ({ children, shadow = 'lg' }) => {
  return (
    <Box
      my={4}
      p={4}
      w="100%"
      border="1px solid"
      borderColor="gray.200"
      borderRadius={10}
      shadow={!!shadow ? shadow : 'none'}
    >
      {children}
    </Box>
  );
};
