import { useIntlLocale } from 'hooks/useIntlFormat';

export const useSchedules = () => {
  const [locale] = useIntlLocale();

  const allWeekDays: Array<string> = [...Array(7)].map((x, i) =>
    new Intl.DateTimeFormat(locale, { weekday: 'long' }).format(new Date(2018, 0, i + 1))
  );

  const today = new Intl.DateTimeFormat(locale, { weekday: 'long' }).format(new Date().getTime());
  const tomorrow = new Intl.DateTimeFormat(locale, { weekday: 'long' }).format(
    new Date().setDate(new Date().getDate() + 1)
  );

  const getWeekDayFromNum: (day: number) => string = (day) => allWeekDays[day];

  return [allWeekDays, today, tomorrow, getWeekDayFromNum] as const;
};

export const isTimeInFuturOfToday = (time: string): boolean => {
  const now = new Date();
  const hours = ('0' + now.getHours()).slice(-2);
  const minutes = ('0' + now.getMinutes()).slice(-2);
  const nowNormalized = `${hours}:${minutes}`;

  return nowNormalized < time;
};
