import { useRouter } from 'next/router';
import { useState } from 'react';

export const useIntlFormat = () => {
  const { locale, defaultLocale } = useRouter();
  const [formatter] = useState<Intl.ListFormat>(
    new Intl.ListFormat(locale || defaultLocale, {
      style: 'long',
      type: 'conjunction',
    })
  );

  return [formatter];
};

export const useIntlLocale = () => {
  const { locale, defaultLocale } = useRouter();
  const localeToReturn = locale || defaultLocale || 'fr';
  return [localeToReturn];
};
