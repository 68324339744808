import { Button, Container, Divider, Flex, Heading } from '@chakra-ui/react';

import Link from 'next/link';
import React from 'react';

export const Owner = () => {
  return (
    <Container mt={4}>
      <Flex direction="column" gap={4}>
        <Heading as="h2">Un passionné de pizza avant tout ✨</Heading>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure autem alias, aliquid neque
          consectetur quisquam, quis a provident accusantium eos tempora modi voluptatibus, maxime
          magnam rerum cum aperiam amet ex?
        </p>

        <Link href="/listing" passHref>
          <Button size={'lg'} variant={'outline'} colorScheme="red" m="auto">
            Nos Pizzas !
          </Button>
        </Link>
      </Flex>
    </Container>
  );
};
