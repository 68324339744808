import { Box, Button, Flex, Stack } from '@chakra-ui/react';
import { FiCalendar } from 'react-icons/fi';
import React, { FC } from 'react';

import { Adress } from 'components/common/Adress';
import { Card } from 'components/common/Card';
import { ITimeDependentContent } from 'misc/interfaces';
import { Time } from 'components/common/Time';
import { GeoLocBtn } from 'components/main/GeoLocBtn';

interface Props {
  showCalendar: () => void;
  businessName: string;
  content: ITimeDependentContent;
}

export const ScheduleCardMaxi: FC<Props> = ({
  businessName,
  showCalendar,
  content: { city, street, sentence, startTime, endTime },
}) => {
  return (
    <Card>
      <Stack gap={2}>
        <Box>
          {businessName} sera présent <i>{sentence}</i>
        </Box>

        <Time startTime={startTime} endTime={endTime} />
        <Adress street={street} city={city} />

        <Flex direction={'row'} justifyContent="space-between">
          <GeoLocBtn city={city} street={street} label="localiser" />

          <Button
            leftIcon={<FiCalendar />}
            size="sm"
            variant="outline"
            aria-label="Calendrier et horaires"
            onClick={showCalendar}
            shadow="md"
          >
            horaires
          </Button>
        </Flex>
      </Stack>
    </Card>
  );
};
