import { Box, Flex, Text } from '@chakra-ui/react';
import React, { FC } from 'react';

import { FiClock } from 'react-icons/fi';

interface Props {
  startTime: string;
  endTime: string;
}

export const Time: FC<Props> = ({ startTime, endTime }) => {
  return (
    <Flex direction={'row'} align="center">
      <Box mr={2}>
        <FiClock />
      </Box>
      <Box>
        <Text as="time">{startTime}</Text> à <Text as="time">{endTime}</Text>
      </Box>
    </Flex>
  );
};
