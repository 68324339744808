import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { FC, useState } from 'react';

import { FilterByIngredients } from 'components/assistant/FilterByIngredients';

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

export const PizzassistantDrawer: FC<Props> = ({ onClose, isOpen }) => {
  const [stepTitle, setStepTitle] = useState(`Pizz'Assistant`);
  return (
    <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay bg="blackAlpha.300" backdropFilter="auto" backdropBlur="sm" />
      <DrawerContent borderTopRadius="3xl" h="90vh">
        <DrawerHeader borderBottomWidth="1px">
          {stepTitle}
          <DrawerCloseButton />
        </DrawerHeader>
        <DrawerBody>
          <FilterByIngredients onClose={onClose} changeTitle={setStepTitle} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
