import { Box, Flex, Text } from '@chakra-ui/react';
import React, { FC } from 'react';

import { FiNavigation } from 'react-icons/fi';
import { GeoLocBtn } from 'components/main/GeoLocBtn';

interface Props {
  street?: string;
  city?: string;
  showGeoloc?: boolean;
}

export const Adress: FC<Props> = ({ street = '', city = '', showGeoloc = false }) => {
  return (
    <address>
      <Flex direction={'row'} align="center" as="address">
        <Box as="span" mr={2}>
          <FiNavigation />
        </Box>
        <Text as="span">
          {street}, {city}
        </Text>
        {showGeoloc && <GeoLocBtn street={street} city={city} />}
      </Flex>
    </address>
  );
};
