import { Button, Stack, Text } from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';

import { Card } from 'components/common/Card';
import { FiCalendar } from 'react-icons/fi';

interface Props {
  showCalendar: () => void;
  sentence: string;
}

export const ScheduleCardMini: FC<Props> = ({ sentence, showCalendar }) => {
  const [showText, setShowText] = useState(false);
  // hack because of random timezone Serverside on Vercel
  useEffect(() => setShowText(true), []);

  return (
    <Card>
      <Stack gap={2}>
        <Text visibility={showText ? 'visible' : 'hidden'}>{sentence}</Text>
        <Text>
          voir les
          <Button
            leftIcon={<FiCalendar />}
            size="xs"
            variant="outline"
            aria-label="Calendrier et horaires"
            onClick={showCalendar}
            shadow="md"
            ml={2}
          >
            horaires
          </Button>
        </Text>
      </Stack>
    </Card>
  );
};
