import { IAdress, ITimeDependentContent } from 'misc/interfaces';
import { isTimeInFuturOfToday, useSchedules } from 'hooks/useSchedules';

import { FC } from 'react';
import { ScheduleCardMaxi } from './ScheduleCardMaxi';
import { ScheduleCardMini } from './ScheduleCardMini';
import locations from 'data/adresses.json';
import schedules from 'data/schedules.json';

interface Props {
  businessName: string;
  showCalendar: () => void;
}

export const TodayInfos: FC<Props> = ({ businessName, showCalendar }) => {
  const [, today, , getWeekDayFromNum] = useSchedules();

  //TODO: refacto
  const getTodaySchedules = () => {
    const x = schedules.filter((x) => getWeekDayFromNum(x.dayOfWeek) === today);
    return x?.length === 1 ? x[0] : undefined;
  };

  const todaySchedules = getTodaySchedules();

  //TODO: refacto
  const getAdress = (id: number | undefined) => {
    const addresses: Array<IAdress> = locations.filter((x) => x.id === id);
    return addresses.length === 1 ? addresses[0] : undefined;
  };

  if (!todaySchedules) {
    return <ScheduleCardMini showCalendar={showCalendar} sentence={`Fermé aujourd'hui`} />;
  }

  if (todaySchedules?.noon?.end && isTimeInFuturOfToday(todaySchedules.noon?.end)) {
    const fullAdress = getAdress(todaySchedules.noon.address);
    const timeDependentContent: ITimeDependentContent = {
      sentence: 'ce midi',
      startTime: todaySchedules?.noon?.start,
      endTime: todaySchedules?.noon?.end,
      street: fullAdress?.street || '',
      city: `${fullAdress?.zip} ${fullAdress?.city}` || '',
    };
    return (
      <ScheduleCardMaxi
        businessName={businessName}
        showCalendar={showCalendar}
        content={timeDependentContent}
      />
    );
  }

  if (todaySchedules?.afternoon?.end && isTimeInFuturOfToday(todaySchedules.afternoon?.end)) {
    const fullAdress = getAdress(todaySchedules.afternoon.address);
    const timeDependentContent: ITimeDependentContent = {
      sentence: 'ce soir',
      startTime: todaySchedules?.afternoon?.start,
      endTime: todaySchedules?.afternoon?.end,
      street: fullAdress?.street || '',
      city: `${fullAdress?.zip} ${fullAdress?.city}`,
    };
    return (
      <ScheduleCardMaxi
        businessName={businessName}
        showCalendar={showCalendar}
        content={timeDependentContent}
      />
    );
  }

  return <ScheduleCardMini showCalendar={showCalendar} sentence={`Fini pour aujourd hui 🙁`} />;
};
