import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import React, { FC } from 'react';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  title: string;
  Component: FC;
}

export const InfoDrawer: FC<Props> = ({ onClose, isOpen, Component, title }) => {
  return (
    <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay bg="blackAlpha.300" backdropFilter="auto" backdropBlur="sm" />
      <DrawerContent borderTopRadius="3xl" h="90vh">
        <DrawerHeader borderBottomWidth="1px">
          {title}
          <DrawerCloseButton />
        </DrawerHeader>
        <DrawerBody>{Component}</DrawerBody>
        <DrawerFooter>
          <Button onClick={onClose}>Fermer</Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
