import { Box, Heading } from '@chakra-ui/react';

import { FC } from 'react';

interface Props {
  businessName: String;
  subtitle: String;
}

export const Headings: FC<Props> = ({ businessName, subtitle }) => {
  return (
    <Box mb={4}>
      <Heading as="h1" size="2xl">
        {businessName}
      </Heading>
      <Heading as="h2" size="md" noOfLines={2}>
        {subtitle}
      </Heading>
    </Box>
  );
};
