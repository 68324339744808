import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FiMoon, FiSun } from 'react-icons/fi';

import { Adress } from 'components/common/Adress';
import { FC } from 'react';
import { IAdress } from 'misc/interfaces';
import { Time } from 'components/common/Time';
import locations from 'data/adresses.json';
import schedules from 'data/schedules.json';
import { useSchedules } from 'hooks/useSchedules';

interface Props {
  visible: boolean;
  closeHandler: () => void;
}

export const SchedulesModal: FC<Props> = ({ visible, closeHandler }) => {
  const [, today, , getWeekDayFromNum] = useSchedules();

  //TODO: refacto
  const getTodaySchedules = () => {
    const x = schedules.filter((x) => getWeekDayFromNum(x.dayOfWeek) === today);
    return x?.length === 1 ? x[0] : undefined;
  };
  const todaySchedules = getTodaySchedules();

  //TODO: refacto
  const getAdress = (id: number | undefined) => {
    const addresses: Array<IAdress> = locations.filter((x) => x.id === id);
    return addresses.length === 1 ? addresses[0] : undefined;
  };

  const idxSc = !!todaySchedules ? schedules.indexOf(todaySchedules) : 99;

  return (
    <Modal closeOnOverlayClick={false} isOpen={visible} onClose={closeHandler} size="sm">
      <ModalOverlay bg="blackAlpha.300" backdropFilter="auto" backdropBlur="sm" />

      <ModalContent top={'20%'}>
        <ModalHeader>Horaires de la semaine</ModalHeader>

        <ModalCloseButton />

        <ModalBody pb={6}>
          <Accordion allowToggle defaultIndex={[idxSc]}>
            {schedules.map((x) => {
              const morningAdress = getAdress(x.noon?.address);
              const afternoonAdress = getAdress(x.afternoon?.address);

              return (
                <AccordionItem key={x.dayOfWeek}>
                  <Heading as="h2">
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        {getWeekDayFromNum(x.dayOfWeek)}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </Heading>
                  <AccordionPanel pb={4}>
                    <Stack gap={2}>
                      {x.noon?.start && x.noon?.end ? (
                        <Stack>
                          <Time startTime={x.noon?.start} endTime={x.noon?.end} />
                          <Adress
                            street={morningAdress?.street}
                            city={morningAdress?.city}
                            showGeoloc
                          />
                        </Stack>
                      ) : (
                        <Flex direction={'row'} gap={2} alignItems="center" color={'gray'}>
                          <FiSun />
                          <i>Fermé le midi</i>
                        </Flex>
                      )}
                      <hr />
                      {x.afternoon?.start && x.afternoon?.end ? (
                        <Stack>
                          <Time startTime={x.afternoon?.start} endTime={x.afternoon?.end} />
                          <Adress
                            street={afternoonAdress?.street}
                            city={afternoonAdress?.city}
                            showGeoloc
                          />
                        </Stack>
                      ) : (
                        <Flex direction={'row'} gap={2} alignItems="center" color={'gray'}>
                          <FiMoon />
                          <Text as="i">Fermé le soir</Text>
                        </Flex>
                      )}
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </ModalBody>

        <ModalFooter>
          <Button variant={'outline'} onClick={closeHandler}>
            Fermer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
