import { Button, Flex, Heading } from '@chakra-ui/react';
import React, { FC } from 'react';

import { AppDataActions } from 'misc/enums';
import { Card } from 'components/common/Card';
import { useAppDataContext } from 'misc/appDataCtxt';
import { useIntlFormat } from 'hooks/useIntlFormat';
import { useToast } from '@chakra-ui/react';

interface Props {
  heading: string;
  ingredientsOfCategory: Array<string>;
  maxNbIngredients: number;
}

export const IngredientsByCategory: FC<Props> = ({
  ingredientsOfCategory,
  heading,
  maxNbIngredients,
}) => {
  const { dispatch, state } = useAppDataContext();
  const toast = useToast();
  const [intlFormatter] = useIntlFormat();

  const onSelectIngredient = (selectedIngredient: string) => {
    const currentFilterdIngredients = state.filter?.ingredients || [];
    const nbFilterdIngredients = currentFilterdIngredients.length;
    const isIngredientAlreadyFiltered = currentFilterdIngredients.includes(selectedIngredient);

    if (nbFilterdIngredients === 0)
      dispatch({
        type: AppDataActions.updateFilter,
        value: {
          filter: {
            ...state.filter,
            ingredients: [selectedIngredient],
          },
        },
      });

    if (nbFilterdIngredients === maxNbIngredients && !isIngredientAlreadyFiltered) {
      const noDup = 'duplicate';
      if (!toast.isActive(noDup)) {
        toast({
          id: noDup,
          title: `Nombre d'ingrédient Max`,
          description: `Vous avez déja selectionné ${maxNbIngredients} ingrégients: ${intlFormatter.format(
            currentFilterdIngredients
          )}`,
          status: 'warning',
          position: 'top',
          duration: 4000,
          variant: 'subtle',
          isClosable: true,
        });
      }

      return;
    }

    let ingredients;
    if (nbFilterdIngredients <= maxNbIngredients) {
      ingredients = currentFilterdIngredients.includes(selectedIngredient)
        ? currentFilterdIngredients.filter((i) => i !== selectedIngredient)
        : [...currentFilterdIngredients, selectedIngredient];

      dispatch({
        type: AppDataActions.updateFilter,
        value: {
          filter: {
            ...state.filter,
            ingredients,
          },
        },
      });
    }
  };

  return (
    <Card>
      <Heading as="h3" size="md" mb={4}>
        {heading}
      </Heading>
      <Flex wrap={'wrap'} gap={2}>
        {ingredientsOfCategory.map((x) => {
          const isThisIngredientIncluded = state.filter?.ingredients?.includes(x);
          return (
            <Button
              key={x}
              size="sm"
              variant="outline"
              colorScheme={isThisIngredientIncluded ? 'red' : 'grey'}
              shadow={isThisIngredientIncluded ? '0' : 'md'}
              onClick={() => onSelectIngredient(x)}
              isActive={isThisIngredientIncluded}
            >
              {x}
            </Button>
          );
        })}
      </Flex>
    </Card>
  );
};
